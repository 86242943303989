
import Vue from "vue"
import type { SwiperOptions } from "swiper"
import indexSwiperMixin from "@/mixins/index-swiper"
import type { Partner } from "@/types/api/partner"

export default Vue.extend({
  mixins: [indexSwiperMixin],

  data: () => ({
    partners: [] as Partner[],
  }),

  async fetch () {
    const [res, err] = await this.$api.partner.getPartners({
      params: {
        "filter[front]": true,
        "sort[list]": "asc",
      },
    })
    if (err) console.error(err)
    else this.partners = this.$api.helpers.getList(res)
  },

  computed: {
    swiperOptions (): SwiperOptions {
      return {
        spaceBetween: 20,
        slidesPerView: "auto",
        navigation: {
          prevEl: `#${this.prevId}`,
          nextEl: `#${this.nextId}`,
          disabledClass: "index-swiper-nav-disabled",
        },
        autoplay: true,
        loop: true,
        loopedSlides: this.partners.length,
      }
    },
  },

  methods: {
    getLogo (partner: Partner): string {
      return partner?.logo?.ru?.url || ""
    },
  },
})
